.timeline-view {
  background-color: white;
  padding: 1rem 1rem 1rem 0;
  //   overflow: hidden;
  //   margin-right: 100px;

  li.ant-timeline-item {
    margin-right: 250px;
  }

  div.ant-timeline-item-content {
    width: calc(100% - 12px) !important;
  }

  .center {
    text-align: center;
  }

  .time-info {
    text-align: end;
    border: 1px solid #e6e6e6;
    padding: 0 1rem 1rem;
    border-radius: 15px;
    width: 300px;
    margin: 0 auto;

    .head {
      text-align: center;
    }
  }

  //   div.timeline_container div.ant-timeline-item-label {
  //     width: calc(18% - 12px) !important;
  //   }

  //   div.timeline_container div.ant-timeline-item-content {
  //     left: calc(19% - 4px) !important;
  //     width: calc(79% - 4px) !important;
  //   }

  //   div.timeline_container div.ant-timeline-item-tail,
  //   div.ant-timeline-item-head {
  //     left: 19% !important;
  //   }
}

.timelog-table {
  .selected-row {
    background-color: #e6e6e6;
  }
}
