/** @format */

.login-container {
  display: flex;
  justify-content: center;
  width: 100vw;
  background-color: rgb(233, 250, 255);
  min-height: 100vh;
  background-color: #00dbde;
  background-image: linear-gradient(90deg, #00dbde 0%, #fc00ff 100%);
}

.login-container .login-form {
  background-color: white;
  margin: 5% 8px;
  padding: 24px 24px 8px 24px;
  border-radius: 12px;
  text-align: center;
  height: 100%;
  .logo {
    height: 200px;
    margin-bottom: 8px;
    border-radius: 16px;
  }
  .description {
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5;
    color: #6e6d7a;
    font-family: "Segoe UI", SegoeUI, "Helvetica Neue", Helvetica, Arial,
      sans-serif;
  }
}

.login-container .login-head {
  h2 {
    color: rgb(94, 108, 132);
    font-weight: bold;
    font-size: 18px;
  }
}
