.pms-settings {
  display: flex;
  .view {
    width: 100%;
    margin: 16px;
  }
  .settings-table-head {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .shadow {
    box-shadow: rgb(0 0 0 / 10%) 0px 0px 10px;
  }
}
